@import "~@dewo/app/styles/theme.less";

.list {
  padding-top: 16px;

  padding-left: 12px;
  padding-right: 12px;

  @media (min-width: @screen-sm) {
    padding-left: 32px;
    padding-right: 32px;
  }
}
