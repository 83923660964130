@import "~@dewo/app/styles/theme.less";

.integrationcard {
  :global {
    .ant-card-head {
      border-radius: 4px;
      background: @body-background-secondary;
    }

    .ant-card-body  {
      margin: 0;
      padding: 0;
      border: none;
      background: @body-background-secondary;
    }
  }
  
  .collapse > :global(.ant-collapse-item) > :global(.ant-collapse-content) > :global(.ant-collapse-content-box) {
    margin: 20px;
  }
}
