@import "~@dewo/app/styles/theme.less";

.extraButtons {
  @media (max-width: @screen-md) {
    display: none;
  }
}

.projectHeader {
  width: 100%;
  :global(.ant-page-header-heading-left) {
    flex: 0 auto;
  }
}
