@import "~@dewo/app/styles/theme.less";

.empty {
  border-style: dashed;
  border-width: 3px;
  border-radius: @border-radius-base * 2;
  background: unset;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: @body-background-secondary;
  }
}
