@import "~@dewo/app/styles/theme.less";

.card:global(.ant-card) {
  height: 100%;
  min-height: 144px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: @body-background-secondary;
  cursor: pointer;

  :global(.ant-card-body) {
    padding: 20px;
  }

  &:hover {
    background: @component-background;
  }
}

.gatingLabel {
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 2px;
}
