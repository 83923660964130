@import "~@dewo/app/styles/theme.less";

.blockbutton {
  text-align: left;
  padding-left: 6px;
  padding-right: 6px;
  color: fade(@white, 75%);
  :global(.anticon) {
    opacity: 0.6;
  }
  &.active,
  &:hover {
    background-color: @component-background;
  }
}
