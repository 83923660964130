@import "~@dewo/app/styles/theme.less";

.tabs {
  height: 100%;
  overflow-y: auto;

  > div[data-rbd-droppable-id="tab-bar"] > div[role="tablist"]
  > :global(.ant-tabs-nav-wrap)
  > :global(.ant-tabs-nav-list) {
    flex: 1;

    padding-left: 0;
    padding-right: 0;

    @media (min-width: @screen-sm) {
      padding-left: 16px;
      padding-right: px;
    }
  }

  > div[role="tablist"] {
    padding-left: 12px;
    padding-right: 12px;
  
    @media (min-width: @screen-sm) {
      padding-left: 32px;
      padding-right: 32px;
    }

    > :global(.ant-tabs-nav-wrap)
    > :global(.ant-tabs-nav-list) {
      flex: 1;  
    }
  }

  :global {
    .ant-tabs-tab div[role="tab"] {
      display: flex;
      align-items: center;
    }

    .ant-tabs-nav[role="tablist"] {
      background-color: @body-background-secondary;
      margin-bottom: 0;

      .ant-tabs-nav-operations {
        display: none;
      }
    }

    .ant-tabs-tabpane {
      display: flex;
      flex-direction: column;
    }
  }
}

.toolbar {
  column-gap: 16px;
  flex-wrap: nowrap;
}

.tab {
  // https://stackoverflow.com/a/67075990/6912118
  [data-rbd-draggable-id] {
    left: auto !important;
    top: auto !important;
  }
  .tabContent {
    margin-left: 16px;
    margin-right: 16px;
  }
}
